import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
  this.auth.currentUser.sendEmailVerification({
    url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
  this.auth.onAuthStateChanged(authUser => {
    if (authUser) {
      this.user(authUser.uid)
        .once('value')
        .then(snapshot => {
          let dbUser = snapshot.val();

          // Replace "." with "," for Firebase compatibility
          const sanitizedEmail = authUser.email.replace(/\./g, ',');

          console.log('Sanitized Email:', sanitizedEmail); // Logging the sanitized email

          // Check if the sanitized email exists in the payments node
          this.db.ref(`payments/${sanitizedEmail}`).once('value').then(paymentSnapshot => {
            const emailExistsInPayments = paymentSnapshot.exists();
            
            console.log('Email exists in payments node:', emailExistsInPayments); // Logging if the email exists in payments

            const hasPaid = emailExistsInPayments ? true : false;

            console.log('Has paid:', hasPaid); // Logging the hasPaid value

            // If dbUser is null or undefined, initialize it as an empty object
            if (!dbUser) {
              dbUser = {};
            }
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth, db user, and payment status
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              hasPaid: hasPaid,  // add the hasPaid status here
              ...dbUser,
            };

            next(authUser);
          });
        });
    } else {
      fallback();
    }
  });

// *** User API ***

user = uid => this.db.ref(`users/${uid}`);
users = () => this.db.ref('users');

// Question API
userCorrectAnswers = uid => this.db.ref(`users/${uid}/correctAnswers`);
userAnswersOverTime = uid => this.db.ref(`users/${uid}/answersOverTime`);
userSubtopicProgress = uid => this.db.ref(`users/${uid}/subtopicProgress`);
questions = () => this.db.ref('questions');

 // *** Chat API ***
  sendMessage = (uid, message) => {
    const timestamp = app.database.ServerValue.TIMESTAMP;
    return this.db.ref(`userChats/${uid}/messages`).push({
      text: message,
      createdAt: timestamp,
      sender: 'user'
    });
  };

  receiveMessage = (uid, callback) => {
    return this.db.ref(`userChats/${uid}/messages`).on('child_added', snapshot => {
      const message = snapshot.val();
      if (message) {
        callback(message);
      }
    });
  };

  // New method to check or initialize user chat data
  checkOrInitializeChat = (uid) => {
    const userChatsRef = this.db.ref(`userChats/${uid}`);
    userChatsRef.once('value', snapshot => {
      if (!snapshot.exists()) {
        userChatsRef.set({
          messages: [] // Initialize with an empty array of messages
        });
      }
    });
  };

recordAnswer = (uid, subtopicId, questionId, isCorrect, topic, subtopicName) => {
  const timestamp = Date.now(); // or Firebase server timestamp, depending on your needs
  
  // Update answers over time with the correctness info
  this.userAnswersOverTime(uid).push({
    timestamp,
    subtopicId,
    questionId,
    isCorrect,
    topic,           // added topic
    subtopicName     // added subtopicName
  });

  if (isCorrect) {
    // If the answer is correct, update the correct answer count
    this.userCorrectAnswers(uid).transaction(currentCount => (currentCount || 0) + 1);

    // console.log("Correct answer registered");
    // Also update the subtopic progress
/*     this.userSubtopicProgress(uid).child(subtopicId).transaction(currentProgress => {
      return {
        ...currentProgress, 
        correct: (currentProgress?.correct || 0) + 1,
        topic,
        subtopicName
      };
    }); */
} else {
  // console.log("Incorrect answer registered");
    // For incorrect answers, update the incorrect count
    // this.userSubtopicProgress(uid).child(subtopicId).transaction(currentProgress => {
    //   return {
    //     ...currentProgress,
    //     incorrect: (currentProgress?.incorrect || 0) + 1,
    //     topic,
    //     subtopicName
    //   };
    // });
    }
  }
}


export default Firebase; 