
import React from 'react';
import { compose } from 'recompose';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import FeedbackComponent from '../FeedbackComponent';
import Footer from '../Footer';


const Dashboard = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div className="flex flex-col h-full py-10">
        <div className="flex-grow flex items-center justify-center w-full py-4">
          <div className="flex flex-col items-center justify-center w-full h-5/4 py-4">
            <FeedbackComponent authUser={authUser} />
          </div>
        </div>
        <div className="flex-shrink-0 w-full">
          <Footer />
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
);


const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Dashboard);