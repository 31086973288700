import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Question from '../Question';
import InteractionArea from '../InteractionArea';
import SidePanel from '../SidePanel';
import { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom';

const Quiz = ({ firebase, authUser }) => {
    const [questions, setQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [loading, setLoading] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState("");
    const [selectedSubtopic, setSelectedSubtopic] = useState("");
    const [submittedIndices, setSubmittedIndices] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);
    const [answersLength, setAnswersLength] = useState(0);
    const [showButton, setShowButton] = useState(true);
    const [countdown, setCountdown] = useState(5);    
    const [isInteractionAreaLoaded, setIsInteractionAreaLoaded] = useState(false);

    const currentQuestion = currentIndex >= 0 && currentIndex < filteredQuestions.length ? filteredQuestions[currentIndex] : null;

    useEffect(() => {
        const fetchQuestions = async () => {
            setLoading(true);
            try {
                const response = await firebase.questions().once('value');
                let questionsData = response.val();
    
                const transformedQuestions = Object.keys(questionsData).map((key) => {
                    const question = questionsData[key];
                    if (!question || !question.topic) return null;
    
                    let optionsArray;
                    try {
                        optionsArray = JSON.parse(question.options.replace(/'/g, '"'));
                    } catch {
                        return null;
                    }
    
                    const options = optionsArray.map((option, optionIndex) => ({
                        id: String.fromCharCode(97 + optionIndex),
                        text: option.substring(3).trim()
                    }));
    
                    return {
                        ...question,
                        options,
                        correctAnswerId: question.correctAnswerId.toLowerCase()
                    };
                }).filter(q => q !== null);
    
                setQuestions(transformedQuestions);
                filterQuestions(transformedQuestions, "Economics");
            } catch (error) {
                console.error("Error fetching questions: ", error);
            }
            setLoading(false);
        };
        fetchQuestions();
    }, [firebase]);

    useEffect(() => {
        const fetchUserAnswers = async () => {
            setLoading(true);
            const ref = firebase.userAnswersOverTime(authUser.uid);
            ref.once('value', snapshot => {
                const length = snapshot.val() ? Object.keys(snapshot.val()).length : 0;
                setAnswersLength(length);
                setShowSubscriptionPrompt(length > 100 && !authUser.hasPaid);
                setLoading(false);
            });
            return () => ref.off();
        };
        fetchUserAnswers();
    }, [firebase, authUser.uid, authUser.hasPaid]); 

    useEffect(() => {
        const fetchCorrectAnswers = () => {
            setLoading(true);
            const ref = firebase.userCorrectAnswers(authUser.uid); 
            ref.on('value', snapshot => {
                const answers = snapshot.val() || 0;
                setCorrectAnswers(answers);
                setLoading(false);
            });
            return () => ref.off();
        };
        fetchCorrectAnswers();
    }, [firebase, authUser.uid]);

    useEffect(() => {
        const savedAnsweredQuestions = JSON.parse(localStorage.getItem('answeredQuestions') || '[]');
        setAnsweredQuestions(savedAnsweredQuestions);
    }, []); 

    useEffect(() => {
        if (notificationMessage) {
            const timer = setTimeout(() => {
                setNotificationMessage(null);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [notificationMessage]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            startCountdown();
        }
    };
    
    const handleSubmit = () => {
        const isAnswerCorrect = selectedOption === currentQuestion.correctAnswerId;
        const { subtopic, id, topic, subtopicName } = currentQuestion;
        firebase.recordAnswer(authUser.uid, subtopic, id, isAnswerCorrect, topic, subtopicName);
        const updatedAnsweredQuestions = [...answeredQuestions, currentQuestion.id];
        setAnsweredQuestions(updatedAnsweredQuestions);
        localStorage.setItem('answeredQuestions', JSON.stringify(updatedAnsweredQuestions));
        if (updatedAnsweredQuestions.length > 100 && !authUser.hasPaid) {
            setShowSubscriptionPrompt(true);
        }
        setNotificationMessage(isAnswerCorrect ? 'correct' : 'incorrect');
        if (isAnswerCorrect) setCorrectAnswers(correctAnswers + 1);
        setSubmittedIndices([...submittedIndices, currentIndex]);
        handleNext();
    };

    const handleNext = () => {
        if (currentIndex < questions.length - 1) {
            setCurrentIndex(currentIndex + 1);
            startCountdown();
        }
    };

    const handleTopicChange = (event) => {
        const topic = event.target.value;
        setSelectedTopic(topic);
        setSelectedSubtopic("");  
        filterQuestions(questions, topic);
    };

    const handleSubtopicChange = (event) => {
        const subtopic = event.target.value;
        setSelectedSubtopic(subtopic);
        filterQuestions(questions, selectedTopic, subtopic);
    };

    const filterQuestions = (questions, topic, subtopic = "") => {
        let filtered = questions;
        if (topic) filtered = filtered.filter(q => q.topic === topic);
        if (subtopic) filtered = filtered.filter(q => q.subtopicName === subtopic);
        setFilteredQuestions(filtered);
        setCurrentIndex(filtered.length > 0 ? 0 : -1);
    };
    
    const getUniqueTopics = () => {
        return [...new Set(questions.map(q => q.topic))];
    };
    
    const getSubtopicsForTopic = (topic) => {
        return [...new Set(questions.filter(q => q.topic === topic).map(q => q.subtopicName))];
    };

    const startCountdown = () => {
        setShowButton(false);
        setCountdown(5);
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown > 1) return prevCountdown - 1;
                clearInterval(timer);
                setShowButton(true);
                return 0;
            });
        }, 1000);
    };

    const handleInteractionAreaLoaded = () => {
        setIsInteractionAreaLoaded(true);
    };

    if (loading || !questions.length) {
        return <img src={`${process.env.PUBLIC_URL}/loading_blue.gif`} alt="Loading" className="h-32 w-32" />;
    }

    return (
        <div className="quiz relative flex flex-col items-center justify-center text-l p-8 w-full mx-auto">
            {showSubscriptionPrompt && (
                <div className="bg-yellow-200 p-6 rounded border border-yellow-400 mb-4">
                    <h2 className="text-lg font-bold mb-4">You've reached the limit!</h2>
                    <p>To continue answering questions, please consider becoming a paid subscriber.</p>
                </div>
            )}
    
            {!showSubscriptionPrompt && (
                <>
                    <div className="mb-4 flex gap-4">
                        <div className="relative">
                            <select onChange={handleTopicChange} value={selectedTopic} className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                <option value="">Select a Topic</option>
                                {getUniqueTopics().map(topic => <option key={topic} value={topic}>{topic}</option>)}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6.293 9.293L10 13l3.707-3.707a.999.999 0 111.414 1.414l-4 4a.999.999 0 01-1.414 0l-4-4a.999.999 0 111.414-1.414z"/></svg>
                            </div>
                        </div>
    
                        {selectedTopic && (
                            <div className="relative">
                                <select onChange={handleSubtopicChange} value={selectedSubtopic} className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                    <option value="">Select Subtopic</option>
                                    {getSubtopicsForTopic(selectedTopic).map(subtopic => <option key={subtopic} value={subtopic}>{subtopic}</option>)}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6.293 9.293L10 13l3.707-3.707a.999.999 0 111.414 1.414l-4 4a.999.999 0 01-1.414 0l-4-4a.999.999 0 111.414-1.414z"/></svg>
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div className="quiz">
                        {currentQuestion ? (
                            <>
                                <Question question={currentQuestion} onOptionChange={handleOptionChange} />
                                <InteractionArea 
                                    onPrevious={handlePrevious} 
                                    onSubmit={handleSubmit} 
                                    onNext={handleNext} 
                                    isQuestionAnswered={answeredQuestions.includes(currentQuestion?.id)}
                                    onLoaded={handleInteractionAreaLoaded}
                                />
                                {notificationMessage && (
                                    <div className="mt-4 py-2 px-4 rounded shadow-lg text-center">
                                        <div className={`py-2 px-4 rounded shadow-lg ${notificationMessage === 'correct' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                            {notificationMessage === 'correct' ? 'Correct!' : 'Oops! That\'s not right.'}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="text-xl md:text-xl font-bold text-center text-blue-600 bg-blue-100 rounded-lg p-4 shadow-lg">
                                Select a Topic and Get Started!!
                            </div>
                        )}
                    </div>

                    <br />
                    <SidePanel
                        questions={filteredQuestions}
                        currentQuestionIndex={currentIndex}
                        showButton={showButton}
                        countdown={countdown}
                    />                    
                </>
            )}
        </div>
    );
};

Quiz.propTypes = {
    firebase: PropTypes.object.isRequired,
    authUser: PropTypes.shape({
        uid: PropTypes.string.isRequired,
        hasPaid: PropTypes.bool.isRequired
    }).isRequired
};

export default withFirebase(Quiz);
