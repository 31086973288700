import React from 'react';
import { AuthUserContext } from '../Session';

const TermsAndConditions = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <TermsAndConditionsAuth authUser={authUser} />
      ) : (
        <TermsAndConditionsNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const TermsAndConditionsAuth = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mx-auto max-w-3xl">
      <h1 className="text-3xl font-semibold">Terms and Conditions***</h1>
      <p className="text-gray-500">Last updated: July 18 2024</p>
      <br />
      <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the FinancePrep.AI website (the "Service") operated by FinancePrep.AI ("us", "we", or "our").
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Acceptance of Terms</h2>
      <p>
        Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Termination</h2>
      <p>
        We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Content</h2>
      <p>
        Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the legality and appropriateness of the content you post.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Links to Other Web Sites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that are not owned or controlled by FinancePrep.AI. FinancePrep.AI has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that FinancePrep.AI shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms and Conditions on this page.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at support@financeprep.ai.
      </p>
    </div>
  );
};

const TermsAndConditionsNonAuth = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mx-auto max-w-3xl">
      <h1 className="text-3xl font-semibold">Terms and Conditions</h1>
      <p className="text-gray-500">Last updated: July 18 2024</p>
      <br />
      <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the FinancePrep.AI website (the "Service") operated by FinancePrep.AI ("us", "we", or "our").
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Acceptance of Terms</h2>
      <p>
        Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Termination</h2>
      <p>
        We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Content</h2>
      <p>
        Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the legality and appropriateness of the content you post.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Links to Other Web Sites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that are not owned or controlled by FinancePrep.AI. FinancePrep.AI has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that FinancePrep.AI shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms and Conditions on this page.
      </p>
      <br />
      <h2 className="text-2xl font-semibold">Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at support@financeprep.ai.
      </p>
    </div>
  );
};

export default TermsAndConditions;
