import React from 'react';
import { compose } from 'recompose';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import Quiz from '../Quiz';
import Footer from '../Footer';


const HomePage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
        <div className="flex flex-col h-screen justify-between px-0">
        <div className="flex-grow flex items-center justify-center w-full px-4 sm:px-8 md:px-16 lg:px-32">
          <div className="flex flex-col items-center justify-center w-full">
            <Quiz authUser={authUser} style={{ maxWidth: '200px' }} />
          </div>
        </div>
        <Footer />
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);