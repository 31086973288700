import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';

const SignUpPage = () => (
  <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
    <div className="relative py-3 sm:max-w-xl sm:mx-auto">
      <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
        <h1 className="text-2xl font-bold mb-6">SignUp</h1>
        <SignUpForm />
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles={};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            roles,
          })
          .then(() => {
            return this.props.firebase.doSendEmailVerification();
          })
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            this.setState({ error });
          });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

      return (
        <form onSubmit={this.onSubmit} className="space-y-8">
          <input
            name="username"
            value={username}
            onChange={this.onChange}
            type="text"
            placeholder="Full Name"
            className="w-full border-b border-gray-300 focus:outline-none focus:border-indigo-500 text-lg"
          />
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
            className="w-full border-b border-gray-300 focus:outline-none focus:border-indigo-500 text-lg"
          />
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
            className="w-full border-b border-gray-300 focus:outline-none focus:border-indigo-500 text-lg"
          />
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm Password"
            className="w-full border-b border-gray-300 focus:outline-none focus:border-indigo-500 text-lg"
          />
          {/* Commenting out the Admin check box
          <label>
            Admin:
            <input
              name="isAdmin"
              type="checkbox"
              checked={isAdmin}
              onChange={this.onChangeCheckbox}
            />
          </label>
          */}
          <button disabled={isInvalid} type="submit" className="w-full p-2 bg-indigo-600 text-white rounded-full tracking-wide font-semibold focus:outline-none focus:shadow-outline hover:bg-indigo-900 shadow-lg cursor-pointer transition ease-in duration-300">
            Sign Up
          </button>
  
          {error && <p className="text-red-500 text-xs mt-2">{error.message}</p>}
        </form>
      );
    }
  }
  
  const SignUpLink = () => (
    <div className="text-center mt-4">
      <div className="text-sm text-gray-700 mb-2">
        Don't have an account?
      </div>
      <Link 
        to={ROUTES.SIGN_UP}
        className="text-lg font-bold text-blue-600 hover:text-blue-800"
      >
        Sign Up
      </Link>
    </div>
  );
  
  const SignUpForm = withRouter(withFirebase(SignUpFormBase));
  
  export default SignUpPage;
  
  export { SignUpForm, SignUpLink };
  
  
  
  
  
  