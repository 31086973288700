import React from 'react';

const CheckoutPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h1 className="text-2xl font-bold mb-4 text-center">Unlock Your CFA Success</h1>

        <p className="mb-4">
          We understand how challenging the CFA exam can be. That's why our quizzing app is designed to give you the most comprehensive and up-to-date practice questions available.
        </p>

        <hr className="my-4"/>

        <div className="mb-4">
          <span className="text-blue-500 font-bold text-xl">Monthly subscription</span>
          <p>Secure a monthly subscription to our premium CFA quizzing tool. Enjoy unlimited questions, personalized tracking, and access to our fine-tuned AI tutor.</p>
        </div>

        <hr className="my-4"/>

        <p className="mb-4">
          Thousands of CFA candidates have trusted our platform to help them succeed. Join the ranks of successful finance professionals who've aced their exams with our help.
        </p>

        <a
          href="https://buy.stripe.com/cN28wC87dg9KgTubIK"
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded w-full text-center block"
        >
          Invest in Your Future – Checkout Now
        </a>

        <p className="mt-4 text-sm text-gray-500 text-center">Your success is a click away. Don't miss out!</p>
      </div>
    </div>
  );
}

export default CheckoutPage;
