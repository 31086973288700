export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id'
export const PASSWORD_FORGET = '/pw-forget';
export const  ABOUT = '/about_us';
export const CONTACT = '/contact_us';
export const TERMS = '/terms_and_conditions';
export const PRIVACY = '/privacy_policy';
export const DASHBOARD = '/dashboard';
export const CHECKOUT = '/checkout';


