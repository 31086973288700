import React, { useEffect, useState } from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

// Sleep utility function
const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};


const ChatMessage = ({ message }) => {
  const rawText = message.props.message.text || '';
  console.log("Message:", message);
  console.log("Raw Text:", rawText);

  // Function to preprocess and format text
  const formatText = (text) => {
    if (typeof text === 'string') {
      return text.split('\n').map((paragraph, index) => (
        <p key={index} className="text-sm md:text-base leading-relaxed mb-2">
          {paragraph}
        </p>
      ));
    } else {
      // Handle the case where text is not a string
      return <p>Invalid message format</p>;
    }
  };

  const displayText = formatText(rawText);
  const isUserMessage = message.props.message.sender === 'UserMessage';

  // Styling for different senders
  const messageStyle = isUserMessage
    ? "bg-blue-400 text-white ml-auto text-right"
    : "bg-gray-100 text-black mr-auto text-justify";

  return (
    <div className={`max-w-md md:max-w-lg lg:max-w-xl mx-2 my-1 p-3 rounded-lg ${messageStyle}`}>
      {displayText}
      <p className="text-xs text-right text-gray-500 mt-2">{new Date(message.props.message.createdAt).toLocaleString()}</p>
    </div>
  );
};



const FetchDataComponent = ({authUser, questions, currentQuestionIndex }) => {
  const currentQuestion = questions[currentQuestionIndex];
  const currentQuestionText = (
    `Current Question: ${currentQuestion.question}
    \nOptions:\n${currentQuestion.options.map((option) => `${option.text}`).join('\n')}
    \nCorrect Answer: ${currentQuestion.correctAnswerId}
    \n Please tease the answer out slowly and help me reason through it by asking me questions. so that I can learn how to solve it myself.
    \nSTOP MESSAGE HERE ----`
  );

  const initialMessageText = `I need help with this question, I am passing the right answer but help me reason through it. Don't tell me which is the right answer.\n\n${currentQuestionText}`;
  
  const [conversationId, setConversationId] = useState('0');
  const [threadId, setThreadId] = useState('');
  const [message, setMessage] = useState(currentQuestionText);
  const [messages, setMessages] = useState([]);
  const [isFirstMessageSent, setIsFirstMessageSent] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // Add this line for loading state


  const clearConversations = async () => {
    try {
      console.log('Previous conversations cleared');
      setMessages([]);
      // setThreadId('');
    } catch (error) {
      console.error('Error clearing conversations:', error);
    }
  };

  useEffect(() => {
    clearConversations().then(() => {
      const newConversationId = `conversation-${Date.now()}`;
      setConversationId(newConversationId);
      sendMessage(initialMessageText)
    });
  }, [currentQuestionIndex]);


  // console.log("Current Question Text:", currentQuestionText);

  const sendMessage = async (textToSend = message) => {
    if (!textToSend) return;
    console.log("Text to send:", textToSend)

    setIsLoading(true); // Set loading to true before sending message
  
    const userMessage = {
      text: textToSend,
      createdAt: Date.now(),
      sender: 'UserMessage'
    };
  
    console.log('Sending message:', userMessage);
  
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setMessage('');
  
    const functionUrl = 'https://us-central1-whatsappapi-33c1c.cloudfunctions.net/socratic_tutor';


    // Introduce a delay to simulate server response lag
    // await sleep(2000); // 2000 milliseconds delay
  
    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          message: textToSend, 
          userId: authUser.uid,
          conversationId: conversationId,
          threadId: threadId // Send existing thread ID
        }),
      });

      console.log("Thread ID:", threadId)
  
      const responseData = await response.json();
      console.log('Response from Firebase function:', responseData);
      console.log("Message sent:", textToSend)
      // console.log("User ID:", authUser.uid)
      // console.log("Conversation ID:", conversationId)
  
      // Set thread ID only if it's not already set
      if (!threadId && responseData.threadId) {
        setThreadId(responseData.threadId);
      }
  
      // Update messages with response from assistant
      if (responseData.AssistantMessage && responseData.AssistantMessage.length > 0) {
        const assistantMessages = responseData.AssistantMessage.map(msg => ({
          text: msg.text.value, // assuming the message text is in the 'value' property
          createdAt: Date.now(),
          sender: 'AssistantMessage'
        }));
        setMessages(prevMessages => [...prevMessages, ...assistantMessages]);
      }
      
      setIsLoading(false); // Set loading to false after receiving response
      
    } catch (error) {
      console.error('Error sending message to Firebase function:', error);
      setIsLoading(false); // Also set loading to false in case of error
    }
  };

  // Send the initial question text when the component mounts or the currentQuestionIndex changes
  useEffect(() => {
    if (!isFirstMessageSent) {
      sendMessage(initialMessageText);
      setIsFirstMessageSent(false); // Set the flag after sending the first message
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const updatedQuestionText = `I need help with this question, I am passing the right answer but help me reason through it. Don't tell me which is the right answer.\n\nCurrent Question: ${currentQuestion.question}\nOptions:\n${currentQuestion.options.map((option) => `${option.text}`).join('\n')}\nCorrect Answer: ${currentQuestion.correctAnswerId}`;
    setMessage(updatedQuestionText);

    // console.log("Updated Question Text:", updatedQuestionText);
  }, [currentQuestionIndex, questions]);

 // We assume the first message is always from the user
  const filteredMessages = messages
    .filter((msg, index) => !(isFirstMessageSent && index === 0))
    .map((msg, index) => {
      const isUser = msg.sender === 'UserMessage';
      return <ChatMessage key={index} message={msg} isUser={isUser} />;
    });

    return (
      <div className="flex flex-col h-full bg-gray-200 p-0 mr-0 ml-0">
        <div className="flex-grow overflow-auto mb-4">
          {filteredMessages.map((msg, index) => (
            <ChatMessage key={index} message={msg} isUser={msg.sender === 'UserMessage'} />
          ))}
        </div>
        <div className="mb-2"> {/* New div for loading indicator */}
          {isLoading && <div className="w-5 h-5 bg-blue-500 rounded-full animate-pulse"></div>}
        </div>
        <div className="flex items-center bg-white p-2 rounded-lg">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendMessage();
            }}
            className="relative w-full" // The form is relative to position the button
          >
            <input
              type='text'
              className="w-full pl-3 pr-16 py-2 rounded-lg border border-gray-300" // pr-16 to provide padding for the button
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              type="submit"
              className="absolute inset-y-0 right-0 px-4 rounded-r-lg bg-blue-500 text-white flex items-center justify-center" // Right aligned button
              style={{ width: '4rem' }} // Adjust the width as necessary
            >
              <img src={`${process.env.PUBLIC_URL}/send-message.png`} alt="Send" className="h-6 w-6" /> {/* Adjust the height and width as needed */}
            </button>
          </form>
        </div>
      </div>
    );
    
};



const Messages = ({ firebase, questions, currentQuestionIndex }) => {
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="p-5">
          <h1 className="text-l font-bold mb-4">Account: {authUser.email}</h1>
          <FetchDataComponent
            authUser={authUser}
            questions={questions}
            currentQuestionIndex={currentQuestionIndex}
          />
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

export default withFirebase(Messages);