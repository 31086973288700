import React from 'react';
import Messages from '../Messages'; // Assuming Messages is in the same folder

class SidePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentQuestionIndex !== prevProps.currentQuestionIndex) {
            // Perform actions when currentQuestion changes
            console.log('Current question has changed in SidePanel');
            // You can add more logic here as needed
        }
    }

    togglePanel = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    renderCountdown() {
        const { countdown } = this.props;
        return (
            <div className="text-black font-bold py-2">
                AI tutor will be available in {countdown} seconds
            </div>
        );
    }

    render() {
        const { questions, currentQuestionIndex, showButton } = this.props;
        const { isOpen } = this.state;

        return (
            <div className="flex items-center justify-center relative">
                {showButton ? (
                    <button onClick={this.togglePanel} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                    Ask AI Tutor
                    </button>
                ) : (
                    this.renderCountdown()
                )}
                <div className={`fixed inset-y-0 right-0 sm:w-2/4 w-full p-10 bg-gray-200 overflow-auto transition-all duration-500 ease-in-out shadow-md z-10 ${isOpen ? '' : 'hidden'}`}>
                    <button onClick={this.togglePanel} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded absolute top-2 right-2">
                        X
                    </button>
                    {isOpen && <Messages questions={questions} currentQuestionIndex={currentQuestionIndex} />}
                </div>
                <div className={`fixed inset-0 bg-black transition-all duration-500 ease-in-out ${isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'}`}></div>
            </div>
        );
    }
}

export default SidePanel;
