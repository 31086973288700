import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Dashboard from '../Dashboard';  
import Checkout from '../Checkout';

import AboutUsPage from '../AboutUs';
import TermsAndConditions from '../TermsAndConditions';
import PrivacyPolicyPage from '../PrivacyPolicy';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => (
  <Router>
    <div>
      <Navigation />

      <hr />
      <Switch>
        <Route exact path={ROUTES.LANDING}>
          <LandingPage/>
        </Route>
        <Route exact path={ROUTES.SIGN_UP}>
          <SignUpPage/>
        </Route>
        <Route exact path={ROUTES.SIGN_IN}>
          <SignInPage/>
        </Route>
        <Route exact path={ROUTES.PASSWORD_FORGET}>
          <PasswordForgetPage/>
        </Route>
        <Route exact path={ROUTES.HOME}>
          <HomePage/>
        </Route>
        <Route exact path={ROUTES.ACCOUNT}>
          <AccountPage/>
        </Route>
        <Route exact path={ROUTES.ADMIN}>
          <AdminPage/>
        </Route>
        <Route exact path={ROUTES.DASHBOARD}>
          <Dashboard/>   {/* Dashboard Route */}
        </Route>
        <Route exact path={ROUTES.ABOUT}>
          <AboutUsPage/>
        </Route>
        <Route exact path={ROUTES.TERMS}>
          <TermsAndConditions/>
        </Route>
        <Route exact path={ROUTES.PRIVACY}>
          <PrivacyPolicyPage/>
        </Route>
        <Route exact path={ROUTES.CHECKOUT}>
          <Checkout/>
        </Route>
      </Switch>
    </div>
  </Router> 
);

export default withAuthentication(App);
