import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const aggregateByTopicOrSubtopic = (answersOverTime, selectedTopic) => {
  const counts = {};

  // Filter and aggregate data based on topic or subtopic
  Object.values(answersOverTime).forEach(answer => {
    if (selectedTopic && answer.topic !== selectedTopic) {
      return; // Skip answers not matching the selected topic
    }

    const key = selectedTopic ? answer.subtopicName : answer.topic; // Use subtopicName if a topic is selected

    if (!counts[key]) {
      counts[key] = 0;
    }
    counts[key] += 1;
  });

  // Calculate total for percentage calculation
  const relevantTotal = Object.values(counts).reduce((a, b) => a + b, 0);

  // Convert counts to percentages
  Object.keys(counts).forEach(key => {
    counts[key] = ((counts[key] / relevantTotal) * 100).toFixed(2);
  });

  return counts;
};

const DoughnutChart = ({ answersOverTime, selectedTopic }) => {
  const dataProgress = aggregateByTopicOrSubtopic(answersOverTime, selectedTopic);

  const doughnutChartData = {
    labels: Object.keys(dataProgress),
    datasets: [
      {
        data: Object.values(dataProgress),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(201, 203, 207, 1)'
        ],
        borderWidth: 1
      }
    ]
  };

  const chartOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}%`;
        }
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        generateLabels: function (chart) {
          const data = chart.data;
          return data.labels.map((label, index) => {
            const dataset = data.datasets[0];
            const value = dataset.data[index];
            return {
              text: `${label}: ${value}%`,
              fillStyle: dataset.backgroundColor[index],
              hidden: isNaN(value) || dataset.data[index] === 0
            };
          });
        }
      }
    }
  };

  return (
    <Doughnut
      data={doughnutChartData}
      options={chartOptions}
    />
  );
};

export default DoughnutChart;
