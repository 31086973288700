import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

const Footer = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <FooterAuth authUser={authUser} />
      ) : (
        <FooterNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const FooterAuth = () => {
    return (
        <footer className="bg-gray-800 text-white text-center py-4 mt-auto">
            <div className="container mx-auto flex flex-wrap sm:justify-between items-center">
                <div className="w-full sm:w-auto mb-4 sm:mb-0">
                    <h5 className="text-lg font-semibold">QuizApp</h5>
                    <p className="text-sm">Your one-stop place for fun and education!</p>
                </div>
                <ul className="space-y-2 sm:space-y-0 sm:space-x-6 mx-auto sm:mx-0">
                    <li><Link to={ROUTES.ABOUT} className="text-sm hover:underline">About Us</Link></li>
                    <li><Link to={ROUTES.TERMS} className="text-sm hover:underline">Terms & Conditions</Link></li>
                    <li><Link to={ROUTES.PRIVACY} className="text-sm hover:underline">Privacy Policy</Link></li>
                </ul>
            </div>
            <div className="mt-4">
                <a href="#" className="mx-2 hover:text-blue-500"><i className="fab fa-facebook-f"></i></a>
                <a href="#" className="mx-2 hover:text-blue-400"><i className="fab fa-twitter"></i></a>
                <a href="#" className="mx-2 hover:text-red-500"><i className="fab fa-instagram"></i></a>
                <a href="#" className="mx-2 hover:text-blue-600"><i className="fab fa-linkedin-in"></i></a>
            </div>
            <p className="mt-4 text-sm">© {new Date().getFullYear()} QuizApp. All rights reserved.</p>
        </footer>
    );
};

const FooterNonAuth = () => {
    return (
        <footer className="bg-gray-800 text-white text-center py-4 mt-auto">
            <div className="container mx-auto flex flex-wrap sm:justify-between items-center">
                <div className="w-full sm:w-auto mb-4 sm:mb-0">
                    <h5 className="text-lg font-semibold">QuizApp</h5>
                    <p className="text-sm">Your one-stop place for fun and education!</p>
                </div>
                <ul className="space-y-2 sm:space-y-0 sm:space-x-6 mx-auto sm:mx-0">
                    <li><Link to={ROUTES.ABOUT} className="text-sm hover:underline">About Us</Link></li>
                    <li><Link to={ROUTES.TERMS} className="text-sm hover:underline">Terms & Conditions</Link></li>
                    <li><Link to={ROUTES.PRIVACY} className="text-sm hover:underline">Privacy Policy</Link></li>
                </ul>
            </div>
            <div className="mt-4">
                <a href="#" className="mx-2 hover:text-blue-500"><i className="fab fa-facebook-f"></i></a>
                <a href="#" className="mx-2 hover:text-blue-400"><i className="fab fa-twitter"></i></a>
                <a href="#" className="mx-2 hover:text-red-500"><i className="fab fa-instagram"></i></a>
                <a href="#" className="mx-2 hover:text-blue-600"><i className="fab fa-linkedin-in"></i></a>
            </div>
            <p className="mt-4 text-sm">© {new Date().getFullYear()} QuizApp. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
