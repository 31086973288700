import React from 'react';

const InteractionArea = ({ onPrevious, onSubmit, onNext, isQuestionAnswered }) => {
    return (
        <div className="flex justify-center mt-6 space-x-4">
            <button
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
                onClick={onPrevious}
            >
                Previous
            </button>

            {/* Conditionally render the Submit button based on isQuestionAnswered */}
            {!isQuestionAnswered && (
                <button
                    className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none ml-2 mr-2"
                    onClick={onSubmit}
                >
                    Submit
                </button>
            )}

            <button
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
                onClick={onNext}
            >
                Next
            </button>
        </div>
    );
};

export default InteractionArea;
