import React from 'react';
import Footer from '../Footer'; // assuming Footer is exported from Footer.js
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const Landing = () => (
    <div className="relative">
        <div className="p-10 md:p-20 bg-white text-gray-900 max-w-screen-xl mx-auto">
            <div className="mb-10">
                <h1 className="text-5xl mb-2 font-bold text-center">Master the CFA Exam with FinancePrep</h1>
                <h2 className="text-3xl mb-5 text-center">AI-Powered Question Bank Designed to Propel Your Learning</h2>
            </div>
            {/* Flex container to center the button */}
            <div className="flex justify-center items-center h-24">
                <Link 
                    to={ROUTES.SIGN_UP} // Adjust with your route
                    className="cta-button py-3 px-6 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 text-center"
                >
                    Start your Free Trial Today! Witness the transformative power of AI in CFA exam preparation.
                </Link>
            </div>
            <div className="mb-10">
                <p className="mb-5 text-lg">
                    With our advanced AI-enabled CFA question bank, we go beyond the traditional learning methods. 
                    Our intelligent system adapts to your learning style, focusing on your weak areas and reinforcing your strengths. 
                    Reacting to your progress, we provide a tailored experience that truly enhances your study efficiency.
                </p>
                {/* Insert image or video here */}
                {/* <div className="image-placeholder h-64 bg-gray-200"></div> */}
                <img src="/photo_landing_3.png" alt="CFA Candidate preparing for the Test using AI powered Tutor" />
            </div>

            <div className="benefits mb-10">
                <ul className="list-disc pl-5">
                    <li className="mb-2">Adaptive Learning: Our system intelligently identifies your strengths and weaknesses, tailoring the question bank to meet your unique learning needs.</li>
                    <li className="mb-2">Comprehensive Coverage: With questions covering every topic in the CFA curriculum, we ensure no stone is left unturned in your exam preparation.</li>
                    <li className="mb-2">Real-time Progress Tracking: Get instant feedback and comprehensive analytics on your performance to plan your studies more effectively.</li>
                    <li className="mb-2">Convenient & User-friendly: Easy to use with an intuitive interface, our platform is built to facilitate learning anytime, anywhere.</li>
                </ul>
            </div>

            <div className="social-proof mb-10 italic">
                Join thousands of successful CFA candidates who have transformed their exam preparation with us. 
                Read their success stories and testimonials here.
                {/* Insert testimonials here */}
                <div class="w-full h-96 overflow-hidden">
                    <img src="photo_landing_2.png" alt="Description" class="w-full h-full object-cover object-center" />
                </div>
            </div>

            <div className="mb-10">
                <p className="reinforcement-statement mb-5">
                    Take control of your CFA exam preparation journey. With our AI-powered question bank, empower your learning process and reach the peak of your potential.
                </p>
                <p className="closing-argument mb-10">
                    Don't let the vastness of the CFA curriculum overwhelm you. Our intelligent, adaptive question bank is here to guide you through your journey, making the process not just manageable, but truly effective. Take a step towards your CFA success with us.
                </p>
            </div>
            <div className="flex justify-center items-center h-24">
                <Link 
                    to={ROUTES.SIGN_UP} // Adjust with your route
                    className="cta-button py-3 px-6 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 text-center"
                >
                    Start your Free Trial Today! Witness the transformative power of AI in CFA exam preparation.
                </Link>
            </div>
        </div>
        <Footer />
    </div>
);

export default Landing;

