import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => {
  return (
    <nav className="bg-gray-800">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {/* <Link to={ROUTES.LANDING} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              Landing
            </Link> */}
            <Link to={ROUTES.HOME} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              Home
            </Link>
            <Link to={ROUTES.ACCOUNT} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              Account
            </Link>
            <Link to={ROUTES.DASHBOARD} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              Dashboard
            </Link>
            {!authUser.hasPaid && (
              <Link to={ROUTES.CHECKOUT} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                Premium
              </Link>
            )}
{/*             {!!authUser.roles[ROLES.ADMIN] && (
              <Link to={ROUTES.ADMIN} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                Admin
              </Link>
            )} */}
          </div>
          <div className="flex items-center">
            <SignOutButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavigationNonAuth = () => (
  <nav className="bg-gray-800">
    <div className="mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <div className="flex items-center">
          <Link to={ROUTES.LANDING} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
            Landing
          </Link>
        </div>
        <div className="flex items-center">
          <Link to={ROUTES.SIGN_IN} className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

export default Navigation;
