import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';  // Import the datalabels plugin

const aggregateAccuracyByTopicOrSubtopic = (answersOverTime, selectedTopic) => {
  const stats = {};

  Object.values(answersOverTime).forEach(answer => {
    if (selectedTopic && answer.topic !== selectedTopic) {
      return;  // Skip answers not matching the selected topic
    }

    const key = selectedTopic ? answer.subtopicName.replace(/Learning Module\s*\d+:\s*/, '') : answer.topic; // Clean up the label

    if (!stats[key]) {
      stats[key] = { correct: 0, total: 0 };
    }

    stats[key].total += 1;
    if (answer.isCorrect) {
      stats[key].correct += 1;
    }
  });

  // Calculate accuracy percentages
  const accuracies = {};
  Object.keys(stats).forEach(key => {
    const { correct, total } = stats[key];
    accuracies[key] = ((correct / total) * 100).toFixed(2);
  });

  return accuracies;
};

const BarChart = ({ answersOverTime, selectedTopic }) => {
  const accuracies = aggregateAccuracyByTopicOrSubtopic(answersOverTime, selectedTopic);

  const barChartData = {
    labels: Object.keys(accuracies),
    datasets: [{
      label: selectedTopic ? `Accuracy % per Subtopic for ${selectedTopic}` : 'Accuracy % per Topic',
      data: Object.values(accuracies),
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderColor: 'rgba(153, 102, 255, 1)',
      borderWidth: 1
    }]
  };

  const barChartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
      }
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: '#555',
        formatter: (value, context) => {
          return `${value}%`;
        }
      }
    }
  };

  return <Bar data={barChartData} options={barChartOptions} />;
};

export default BarChart;
