import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import 'katex/dist/katex.min.css';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';

const preprocessQuestion = (questionText) => {
    const delimiter = '!!!!!';
    const firstIndex = questionText.indexOf(delimiter);
    const lastIndex = questionText.lastIndexOf(delimiter);

    if (firstIndex !== -1 && lastIndex !== -1 && firstIndex !== lastIndex) {
        let before = questionText.substring(0, firstIndex).trim();
        const markdownSection = questionText.substring(firstIndex + delimiter.length, lastIndex).trim();
        let after = questionText.substring(lastIndex + delimiter.length).trim();

        before = before.trim();
        let processedMarkdownText = markdownSection.trim();
        after = after.trim();

        before = before.replace(/\\n/g, "\n");
        processedMarkdownText = processedMarkdownText.replace(/\\n/g, "\n");
        after = after.replace(/\\n/g, "\n");

        return { before, processedMarkdownText, after };
    }

    return { before: questionText.trim(), markdownSection: '', after: '' };
};



const Question = ({ question, onOptionChange }) => {
    const [selectedOption, setSelectedOption] = useState(question.options[0]?.id);

    useEffect(() => {
        onOptionChange({ target: { value: selectedOption }});
    }, [selectedOption, onOptionChange]);

    const { before, processedMarkdownText, after } = preprocessQuestion(question.question);

    const handleOptionChange = (optionId) => {
        setSelectedOption(optionId);
        onOptionChange({ target: { value: optionId }});
    };

    return (
        <div className="p-6 mx-auto bg-white rounded-xl shadow-lg space-y-4" style={{ maxWidth: '1200px' }}>
            {before && (
                <div className="markdown-content p-4 bg-white rounded-md">
                    <ReactMarkdown 
                        className="prose prose-lg sm:prose-lg lg:prose-lg max-w-full max-h-full"
                        rehypePlugins={[rehypeRaw, rehypeSanitize]} 
                        remarkPlugins={[remarkGfm]} 
                    >
                        {before}
                    </ReactMarkdown>
                </div>
                )}
                {processedMarkdownText && (
                <div className="markdown-content p-4 bg-gray-100 rounded-md">
                    <ReactMarkdown 
                        className="prose prose-sm sm:prose-sm lg:prose-sm max-w-full max-h-full"
                        rehypePlugins={[rehypeRaw, rehypeSanitize]} 
                        remarkPlugins={[remarkGfm]} 
                    >
                        {processedMarkdownText}
                    </ReactMarkdown>
                </div>
                )}
               {after && (
                <div className="markdown-content p-4 bg-white rounded-md">
                    <ReactMarkdown 
                        className="prose prose-lg sm:prose-lg lg:prose-lg max-w-full max-h-full"
                        rehypePlugins={[rehypeRaw, rehypeSanitize]} 
                        remarkPlugins={[remarkGfm]} 
                    >
                        {after}
                    </ReactMarkdown>
                </div>
                )}

            {question.options.map(option => (
                <div
                    key={option.id}
                    className={`flex items-center p-3 mt-2 rounded-md cursor-pointer ${
                        selectedOption === option.id ? 'bg-blue-100 border-blue-500' : 'bg-gray-50'
                    } hover:bg-blue-100`}
                    onClick={() => handleOptionChange(option.id)}
                >
                    <input
                        type="radio"
                        name="option"
                        value={option.id}
                        checked={selectedOption === option.id}
                        onChange={() => handleOptionChange(option.id)}
                        className="form-radio h-5 w-5 text-blue-600"
                    />
                    <label htmlFor={option.id} className="ml-3 text-gray-700">{option.text}</label>
                </div>
            ))}
        </div>
    );
};

export default Question;