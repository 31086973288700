import React, { useState, useEffect, useMemo } from 'react';
import { withFirebase } from '../Firebase';
import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  ArcElement,
  BarElement,
  PointElement,
  Tooltip,
  Legend
} from 'chart.js';

// Register the required elements, scales, and plugins
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  ArcElement,
  BarElement,
  PointElement,
  Tooltip,
  Legend
);


const FeedbackComponent = ({ firebase, authUser }) => {
  const [loading, setLoading] = useState(true);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [answersOverTime, setAnswersOverTime] = useState({});
  const [selectedTopic, setSelectedTopic] = useState(null);

  const uid = authUser.uid;

  useEffect(() => {
    const correctAnswersRef = firebase.userCorrectAnswers(uid);
    const answersOverTimeRef = firebase.userAnswersOverTime(uid);

    // Fetch correct answers count
    correctAnswersRef.on('value', snapshot => {
      setCorrectAnswers(snapshot.val() || 0);
      setLoading(false);
    });

    // Fetch answers over time
    answersOverTimeRef.on('value', snapshot => {
      setAnswersOverTime(snapshot.val() || {});
      setLoading(false);
    });

    // // Fetch and aggregate subtopic progress
    // subtopicProgressRef.on('value', snapshot => {
    //   setSubtopicProgress(snapshot.val() || {});
    // });

    return () => {
      correctAnswersRef.off();
      answersOverTimeRef.off();
      // subtopicProgressRef.off();
    };
  }, [firebase, uid]);


  console.log("Correct Answers", correctAnswers)
  console.log("Ansers Over Time", answersOverTime)
  // console.log("Subtopic Progress", subtopicProgress)
  

  
  const processAnswersByTopic = (answersOverTime) => {
    const aggregatedData = {};

    Object.values(answersOverTime).forEach(answer => {
      const { topic, subtopicName, isCorrect } = answer;

      if (!aggregatedData[topic]) {
        aggregatedData[topic] = {};
      }

      if (!aggregatedData[topic][subtopicName]) {
        aggregatedData[topic][subtopicName] = {
          correct: 0,
          total: 0
        };
      }

      aggregatedData[topic][subtopicName].total += 1;
      if (isCorrect === true) {
        aggregatedData[topic][subtopicName].correct += 1;
      }
    });

    return aggregatedData;
  };

  const aggregatedData = processAnswersByTopic(answersOverTime);
  console.log("Agregate Data", JSON.stringify(aggregatedData, null, 2));

  if (loading) {
    return <div>Loading...</div>;
  }


   return (
    <div className="w-full h-full px-8 py-2">
      <select
        value={selectedTopic || ""}
        onChange={e => setSelectedTopic(e.target.value || null)}
        className="w-full md:w-1/2 lg:w-1/3 mb-6 shadow-md p-2 rounded">
        <option value="">All Topics</option>
        <option value="Quantitative Methods">Quantitative Methods</option>
        <option value="Economics">Economics</option>
        <option value="Portfolio Management">Portfolio Management</option>
        <option value="Corporate Issuers">Corporate Issuers</option>
        <option value="Financial Statement Analysis">Financial Statement Analysis</option>
        <option value="Equity Investments">Equity Investments</option>
        <option value="Fixed Income">Fixed Income</option>
        <option value="Derivatives">Derivatives</option>
        <option value="Alternative Investments">Alternative Investments</option>
        <option value="Ethical and Professional Standards">Ethical and Professional Standards</option>
      </select>


      <div className="flex flex-col md:flex-row w-full h-full p-4 gap-4">
        <div className="flex flex-1 min-w-0 p-2">
          <LineChart answersOverTime={answersOverTime} selectedTopic={selectedTopic}/>
        </div>
        <div className="flex flex-1 min-w-0 p-2 items-center justify-center">
        <DoughnutChart
          selectedTopic={selectedTopic}  // This forces React to remount the component when the selected topic changes
          answersOverTime={answersOverTime}
        />
        </div>
        <div className="flex flex-1 min-w-0 p-2">
          <BarChart
          selectedTopic={selectedTopic}  
           answersOverTime={answersOverTime} />
        </div>
      </div>


      {/* {selectedTopic ? (
        <div className="shadow-md p-4 rounded mb-6 w-full md:w-3/4 mx-auto">
          <LineChart filteredAnswers={filteredAnswers} />
        </div>
      ) : (
        <div className="flex flex-col items-center w-full py-5">
          <h4 className="mb-4">Total Correct Answers: {correctAnswers}</h4>
          <div className="flex flex-col md:flex-row w-full md:space-x-5 flex-grow">
            <div className="flex flex-grow md:flex-grow-0 md:w-1/2 mb-6 md:mb-0">
              <div className="w-full shadow-md p-4 rounded">
                <LineChart filteredAnswers={filteredAnswers} />
              </div>
            </div>

            <div className="flex flex-col md:flex-row flex-grow w-full lg:w-3/4 md:space-x-5">
              <div className="flex flex-grow mb-6 md:mb-0">
                <div className="w-full shadow-md p-4 rounded">
                  <DoughnutChart subtopicProgress={subtopicProgress} data={aggregateDataForCharts.doughnutData} />
                </div>
              </div>

              <div className="flex flex-grow-3 h-full">
                <div className="w-full h-full shadow-md p-12 rounded">
                  <BarChart subtopicProgress={subtopicProgress} data={aggregateDataForCharts.barData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  ); 
};

export default withFirebase(FeedbackComponent);
