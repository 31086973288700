import React from 'react';
import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => {
  const handleSignOut = () => {
    // Clear the local storage (either entirely or specific items)
    localStorage.clear();
    // OR if you want to clear specific items:
    // localStorage.removeItem('answeredQuestions');

    // Proceed with the sign out process
    firebase.doSignOut();
  };

  return (
    <button 
      type="button" 
      onClick={handleSignOut}
      className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    >
      Sign Out
    </button>
  );
};

export default withFirebase(SignOutButton);
