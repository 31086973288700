import React from 'react';
import { AuthUserContext } from '../Session';

const  AboutUs= () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <AboutUsAuth authUser={authUser} />
      ) : (
        <AboutUsNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const AboutUsAuth = () => {
    return (
      <div class="bg-white shadow-lg rounded-lg p-6 mx-auto max-w-3xl">
        <h1 class="text-4xl font-bold mb-4">About Us</h1>
          <p class="mb-4 text-lg">Welcome to <strong class="font-bold">Smart Study</strong>, your ultimate guide in mastering the CFA exam. We are an innovative platform employing advanced artificial intelligence technology to revolutionize your learning experience. Our AI-powered question bank offers a comprehensive, tailored, and efficient study plan to transform your exam preparation process.</p>
          <p class="mb-4 text-lg">At Smart Study, we believe in the potential of every learner, and we understand that each individual has a unique learning style. That’s why we've designed our system to adapt to your needs. We focus on your weak areas while reinforcing your strengths, thus providing a truly customized learning experience.</p>
          <h2 class="text-3xl font-bold mt-8 mb-4">Our Features</h2>
          <ul class="list-disc pl-5">
            <li class="mb-2 text-lg">Adaptive learning: Our system intelligently identifies your strengths and weaknesses, tailoring the question bank to meet your unique learning needs.</li>
            <li class="mb-2 text-lg">Comprehensive Coverage: With our comprehensive coverage of every topic in the CFA curriculum, we guarantee that no subject will go unaddressed in your study routine.</li>
            <li class="mb-2 text-lg">Real-time progress tracking: We provide instant feedback and in-depth analytics on your performance. This insightful data allows you to strategically plan your studies, optimizing your learning trajectory.</li>
            <li class="mb-2 text-lg">User-friendly Interface: Our platform ensures that you can learn at your convenience - anytime, anywhere. The power to prepare effectively for the CFA exam is at your fingertips.</li>
          </ul>
          <p class="mb-4 text-lg mt-8">Join us in making your step towards CFA success. <a href="#" class="underline text-blue-600">Start your Free Trial Today</a> and witness firsthand the transformative power of AI in CFA exam preparation.</p>
          <p class="mb-4 text-lg">Smart Study - Empowering Learners, Transforming Outcomes.</p>
        </div>
    );
};

const AboutUsNonAuth = () => {
  return (
    <div class="bg-white shadow-lg rounded-lg p-6 mx-auto max-w-3xl">
      <h1 class="text-4xl font-bold mb-4">About Us</h1>
      <p class="mb-4 text-lg">Welcome to <strong class="font-bold">Smart Study</strong>, your ultimate guide in mastering the CFA exam. We are an innovative platform employing advanced artificial intelligence technology to revolutionize your learning experience. Our AI-powered question bank offers a comprehensive, tailored, and efficient study plan to transform your exam preparation process.</p>
      <p class="mb-4 text-lg">At Smart Study, we believe in the potential of every learner, and we understand that each individual has a unique learning style. That’s why we've designed our system to adapt to your needs. We focus on your weak areas while reinforcing your strengths, thus providing a truly customized learning experience.</p>
      <h2 class="text-3xl font-bold mt-8 mb-4">Our Features</h2>
      <ul class="list-disc pl-5">
        <li class="mb-2 text-lg">Adaptive learning: Our system intelligently identifies your strengths and weaknesses, tailoring the question bank to meet your unique learning needs.</li>
        <li class="mb-2 text-lg">Comprehensive Coverage: With our comprehensive coverage of every topic in the CFA curriculum, we guarantee that no subject will go unaddressed in your study routine.</li>
        <li class="mb-2 text-lg">Real-time progress tracking: We provide instant feedback and in-depth analytics on your performance. This insightful data allows you to strategically plan your studies, optimizing your learning trajectory.</li>
        <li class="mb-2 text-lg">User-friendly Interface: Our platform ensures that you can learn at your convenience - anytime, anywhere. The power to prepare effectively for the CFA exam is at your fingertips.</li>
      </ul>
      <p class="mb-4 text-lg mt-8">Join us in making your step towards CFA success. <a href="#" class="underline text-blue-600">Start your Free Trial Today</a> and witness firsthand the transformative power of AI in CFA exam preparation.</p>
      <p class="mb-4 text-lg">Smart Study - Empowering Learners, Transforming Outcomes.</p>
    </div>
);
};

export default AboutUs;
