import React from 'react';
import { AuthUserContext } from '../Session';

const PrivacyPolicy = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <PrivacyPolicyAuth authUser={authUser} />
      ) : (
        <PrivacyPolicyNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const PrivacyPolicyAuth = () => {
    return (
      <div className="content px-8 py-6 bg-gray-100 text-gray-800">
      <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-500 italic mb-6">Last Updated: July 24, 2023</p>
  
      <div className="my-4">
          <h1 className="text-2xl font-semibold mb-2">Privacy Policy</h1>
          <p className="mb-4">Welcome to our application (the "App"). This Privacy Policy explains how your personal information is collected, used, and disclosed by [Your Company Name]. It also serves to inform you about your rights and choices with respect to your personal information. By using our App, you agree to the collection and use of information in accordance with this policy.</p>
          
          <h2 className="text-xl font-semibold mt-6 mb-2">Information Collection and Use</h2>
          <p className="mb-4">While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to:</p>
          <ul className="list-disc list-inside mb-4">
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data</li>
          </ul>
          <p className="mb-4">We use this information for providing and improving our App. Your information will not be used for purposes other than those described in this policy unless we have obtained your consent, or if not prohibited by law.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Log Data</h2>
          <p className="mb-4">Like many app operators, we collect information that your browser sends whenever you visit our App ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the screens of our App that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Cookies</h2>
          <p className="mb-4">Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. We use "cookies" to collect information in order to improve our App for you.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Service Providers</h2>
          <p className="mb-4">We may employ third-party companies and individuals to facilitate our App, to provide the App on our behalf, to perform App-related services or to assist us in analyzing how our App is used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Security</h2>
          <p className="mb-4">The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Changes to This Privacy Policy</h2>
          <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      </div>
  
      <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].</p>
  </div>
    );
};

const PrivacyPolicyNonAuth = () => {
    return (
      <div className="content px-8 py-6 bg-gray-100 text-gray-800">
      <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-500 italic mb-6">Last Updated: July 24, 2023</p>
  
      <div className="my-4">
          <h1 className="text-2xl font-semibold mb-2">Privacy Policy</h1>
          <p className="mb-4">Welcome to our application (the "App"). This Privacy Policy explains how your personal information is collected, used, and disclosed by [Your Company Name]. It also serves to inform you about your rights and choices with respect to your personal information. By using our App, you agree to the collection and use of information in accordance with this policy.</p>
          
          <h2 className="text-xl font-semibold mt-6 mb-2">Information Collection and Use</h2>
          <p className="mb-4">While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to:</p>
          <ul className="list-disc list-inside mb-4">
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data</li>
          </ul>
          <p className="mb-4">We use this information for providing and improving our App. Your information will not be used for purposes other than those described in this policy unless we have obtained your consent, or if not prohibited by law.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Log Data</h2>
          <p className="mb-4">Like many app operators, we collect information that your browser sends whenever you visit our App ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the screens of our App that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Cookies</h2>
          <p className="mb-4">Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. We use "cookies" to collect information in order to improve our App for you.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Service Providers</h2>
          <p className="mb-4">We may employ third-party companies and individuals to facilitate our App, to provide the App on our behalf, to perform App-related services or to assist us in analyzing how our App is used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Security</h2>
          <p className="mb-4">The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
  
          <h2 className="text-xl font-semibold mt-6 mb-2">Changes to This Privacy Policy</h2>
          <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      </div>
  
      <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].</p>
  </div>
    );
};

export default PrivacyPolicy;
