import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns'; // Ensure date-fns is installed

const LineChart = ({ answersOverTime, selectedTopic }) => {
  const lineChartData = useMemo(() => {
    console.log("Received answersOverTime:", answersOverTime);
    console.log("Selected topic:", selectedTopic);
    // Check if answersOverTime is valid before proceeding
    if (!answersOverTime || typeof answersOverTime !== 'object') {
      return {
        labels: [],
        datasets: [{
          label: '% Correct Answers Over Time',
          data: [],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.2,
          pointRadius: 0
        }]
      };
    }

    // Sort answers by timestamp
    const sortedAnswers = Object.values(answersOverTime).sort((a, b) => a.timestamp - b.timestamp);

    // Filter answers by the selected topic
    const filteredAnswers = selectedTopic ? sortedAnswers.filter(answer => answer.topic === selectedTopic) : sortedAnswers;

    // If no answers are found for the selected topic, fall back to the original sorted answers
    const finalAnswers = selectedTopic && filteredAnswers.length === 0 ? sortedAnswers : filteredAnswers;

    let correctCount = 0;
    const dataPoints = finalAnswers.map((answer, index) => {
      if (answer.isCorrect) correctCount++;
      const percentageCorrect = (correctCount / (index + 1)) * 100;
      const dateLabel = format(new Date(answer.timestamp), 'M/d/yy'); // Using date-fns for formatting

      return { dateLabel, percentageCorrect };
    });

    return {
      labels: dataPoints.map(point => point.dateLabel),
      datasets: [{
        label: '% Correct Answers Over Time',
        data: dataPoints.map(point => point.percentageCorrect),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.2,
        pointRadius: 0
      }]
    };
  }, [answersOverTime, selectedTopic]);

  return (
    <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false }} />
  );
};

export default LineChart;
