import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div className="flex items-center justify-center h-screen bg-gray-200">
    <div className="bg-white rounded-lg shadow-lg p-8 sm:h-6/6 sm:w-3/4 w-full h-full flex flex-col justify-center items-center max-w-md">
      <h1 className="text-4xl font-bold mb-4 text-gray-900">Sign In</h1>
      <p className="text-lg mb-4 text-gray-700">Welcome back! Please sign in to continue.</p>
      <SignInForm />
      <div className="mt-4 p-4 border-2 border-gray-200 rounded-lg">
        <SignUpLink />
      </div>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0 sm:space-x-4 mt-4">
        <SignInGoogle />
        {/* <SignInFacebook /> */}
        {/* <SignInTwitter /> */}
      </div>
      <div className="flex space-x-4 mt-4">
        <PasswordForgetLink />
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit} className="w-full">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
              Email Address
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                isInvalid && 'opacity-50 cursor-not-allowed'
              }`}
              disabled={isInvalid}
              type="submit"
            >
              Sign In
            </button>
          </div>
          {error && <p className="text-red-500 text-xs italic mt-4">{error.message}</p>}
        </div>
      </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        return this.props.firebase
          .user(socialAuthUser.user.uid)
          .once('value')
          .then(snapshot => {
            if (!snapshot.exists()) {
              return this.props.firebase.user(socialAuthUser.user.uid).set({
                username: socialAuthUser.user.displayName,
                email: socialAuthUser.user.email,
                roles: {},
              });
            }
          });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button
          type="submit"
          className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded flex items-center shadow-md"
        >
          <img src={`${process.env.PUBLIC_URL}/images/google-icon-logo-svgrepo-com.svg`} alt="Google logo" className="w-6 h-6 mr-2" />
          <span>Sign In with Google</span>
        </button>
        {error && <p className="text-red-500 text-xs italic mt-4">{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        return this.props.firebase
          .user(socialAuthUser.user.uid)
          .once('value')
          .then(snapshot => {
            if (!snapshot.exists()) {
              return this.props.firebase.user(socialAuthUser.user.uid).set({
                username: socialAuthUser.additionalUserInfo.profile.name,
                email: socialAuthUser.additionalUserInfo.profile.email,
                roles: {},
              });
            }
          });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button
          type="submit"
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center shadow-md"
        >
          {/* <FacebookIcon className="w-6 h-6 mr-2" /> */}
          <span>Sign In with Facebook</span>
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button
          type="submit"
          className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded flex items-center shadow-md"
        >
          {/* <TwitterIcon className="w-6 h-6 mr-2" /> */}
          <span>Sign In with Twitter</span>
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));
const SignInGoogle = withRouter(withFirebase(SignInGoogleBase));
const SignInFacebook = withRouter(withFirebase(SignInFacebookBase));
const SignInTwitter = withRouter(withFirebase(SignInTwitterBase));
export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
